import Api from '@/services/Index';

const getAll = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/coordinator-courses')
}

const updateCourseByCoordinator = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course-by-coordinator/'+courseId, formData)
}

const getProgramCoursesAll = async (programCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-courses/'+programCode)
}

const updateCourseByProgramCoordinator = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course-by-program-coordinator/'+courseId, formData)
}

const updateCourseByAdmin = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course/'+courseId, formData)
}

export default {
    getAll,
    updateCourseByCoordinator,
    getProgramCoursesAll,
    updateCourseByProgramCoordinator,
    updateCourseByAdmin
}
